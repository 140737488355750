import React from "react";
import Head from "next/head";
import Notfound from './../src/components/pages/notfound/Notfound';
const Notfoundpage = () => {
  return (
    <>
      <Head>
        <title>Go Bermondsey</title>
        <meta name="description" content="Go Bermondsey" />
        <meta itemProp="name" content="" />
        <meta itemProp="description" content="" />
        <meta itemProp="image" content="" />
      </Head>
      
      <Notfound />
    </>
  );
};

export default Notfoundpage;
