import React, {useEffect} from 'react';
import Link from "next/link";
import Routes from '../../../routes/Routes';
import { setHeaderSpace } from "../../../utils/CommonFunctions";
const Notfound = () => {

    /* for set header */
    useEffect(() => {
        setHeaderSpace();
    }, []);
    return (
        <>
        <div className="notfound_outer_container">
            <div id="setSpacer" className="setBanner">
                <div className="bannerInner">
                    <div className="mc_container notfound">
                        <div className="nf_i_cb">
                            <h1>404</h1>
                            <h3>Not Found</h3>
                            <p>The resource requested could not be found on this server!</p>
                            <Link href={Routes?.home}>Back to Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Notfound;